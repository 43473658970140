/*Account*/
.customer-account{
  &-login, &-create, &-forgotpassword, &-createpassword{

    .block-title {
      padding-bottom: 1rem;
      text-align: left;
      justify-content: flex-start;
      padding-top: 3rem;

    }
    .page-title{
      text-align: center;
    }
  }
}

.account {
  .page-title {
    &-wrapper {
      padding-bottom: 2.5rem;
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;

      h1 {
        margin-bottom: 0;
      }
    }
  }
  .actions-toolbar {
    text-align: left !important;
    margin-top: 1rem;
  }

  .sidebar {
    .block{
      .title{
        display: none;
      }
    }
    &-main {
      padding-top: 10.5rem;

      a, strong {
        line-height: 2.6rem;
        display: block;
        font-size: 2rem;
      }

      a {
        padding-bottom: 1.1rem;
      }

      .current {
        padding-bottom: 1.1rem;
        color: $colorPrimary;
        a{
          color: $colorPrimary;
        }
      }

      @media only screen and (max-width: 1024px) {
        width: 100%;
        padding-bottom: 1.6rem;
        padding-top: 1.6rem;
        .current, a {
          padding-bottom: 0rem;
        }
      }

      .account-nav {
        padding-left: 2rem;
        padding-right: 2rem;
        @media only screen and (max-width: 1024px) {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
  p.toolbar-amount {
    display: none;
  }
  .order-products-toolbar.toolbar {
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 2rem;
    text-align: right;
    margin: 0;
    select {
      font-size: 1.4rem;
      font-weight: bold;
      line-height: 2rem;
      text-align: right;
    }
  }
  .table-wrapper {
    .table-caption {
      display: none;
    }
    table {
      tr {
        border-bottom: 1px solid $colorGray;
        td.col {
          text-align: left;
          padding: .7rem;
          font-size: 1.4rem;
          &.actions {
            @include flexWrap;
            height: 100%;
            align-items: center;
            justify-content: flex-start;
            .delete{
              margin-left: 1.5rem;
            }
          }
        }
        th {
          text-align: left;
          border-bottom: 0;
        }
      }
    }
  }
  span.order-status, .order-date, .actions-toolbar.order-actions-toolbar a {
    @include fontSmaller;
  }
  .table-wrapper.order-items {
    margin-bottom: 1.5rem;
    tr {
      display: flex;
      justify-content: space-between;
      td, th {
        display: block;
        &.col{
          border:0;
        }
        &.name {
          strong{
            padding-top: 0;
          }
        }
        &.sku {
        }
        &.price {
          padding-left: 4rem;
        }
        &.qty {

          ul.items-qty {
            @include flex;
            .item {
              padding-right: 1rem;
            }
          }
        }
        &.subtotal {
          text-align: right;

        }
      }
    }
    tfoot {
      margin-top: 2rem;
      display: block;
    }
  }
  .order-details-items.ordered {
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    .order{
      &-title{
        margin-bottom: 4rem;
      }
    }
    table {
      tbody {
        border: 0;
      }
      tfoot{
        tr{
          border:0;
        }
        td, th{
          padding: .3rem .5rem;
          border:0;
        }
      }
    }
  }
  ul.items.order-links {
    display: none;
  }
  .limiter {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    @include flex;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border:0;
    select#limiter {
      width: 7.5rem;
      margin-left: 0.5rem;
      margin-right: 1rem;
    }
  }
  .box {
    position: relative;
    margin-bottom: 1rem;
    .box-content {
      padding-bottom: 1.5rem;
    }
    .box-actions {
      position: absolute;
      right: 0;
      top: 0;
      @include flexStart;
      justify-content: space-between;
    }
  }
  .block-title, .legend {
    position: relative;
    width: 100%;
    margin-bottom: 2rem;
    justify-content: flex-start;
    font-weight: bold;
    padding-top: 1rem;
    border-bottom: 0.1rem solid $colorBlack;
    padding-bottom: 1rem;
    > *{
      font-size: 2rem;
    }
  }

  form {
    &.form-address-edit {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      fieldset {
        width: 49%;
        .field{
          &.street{
            > .label{
              display: none;
            }
          }
        }
      }
      @media only screen and (max-width: 768px) {
        justify-content: center;
        fieldset {
          width: 100%;
        }
      }
    }
  }
  .column{
    .block{
      &-content{
        @include flexWrap;
        .table{
          &-wrapper{
            width: 100%;
          }
        }
        .box{
          width: 49%;
          padding: 2rem;
          padding-right: 6.5rem;
          margin-right: 2%;
          border: 1px solid $colorGray;
          position: relative;
          &:nth-of-type(2n){
            margin-right: 0;
          }
          &-title{
            margin-bottom: 1rem;
            display: block;
            width: 100%;
            word-break: break-all;
          }
          &-actions{
            top: 2rem;
            right: 0;
          }
          &-content{
            padding-bottom: 0;
            font-size: 1.6rem;
          }
        }
      }
    }
    &-dashboard{
      &-addresses{
        .block-title {
          .action {
            display: none;
          }
        }
      }
      &-orders {
        .block-content {
          > * {
            flex-basis: 100%;
          }
        }

        table {
          width: 100%;
          .table-caption {
            text-align: left;
          }
          th {
            text-align: left;
            padding: 0 1rem 1rem;
          }
        }
      }
    }
    &-addresses{
      &-default{
        padding-bottom: 2.5rem;
      }
      &-list {
        .block {
          &-title {
            margin-bottom: 0;
          }
          &-content{
            padding-top: 5rem;
          }
        }
        p.empty {
          margin-top: 1rem;
          margin-bottom: 1rem;
          @include fontSmaller;
        }
      }
    }
  }
  .block.block-dashboard-addresses



  .wishlist {
    .limiter {
      margin: 0;
    }
    .product-item-info {
      @include flex;
      flex-wrap: wrap;
      > div, strong {
        width: 100%;
      }
      .price-box.price-configured_price {
        font-weight: bold;
      }
      .price-details {
        @include fontSmaller;
        padding-top: 1rem;
      }
      .product-item-inner {
        padding-top: 1.5rem;
        position: relative;
        .field.comment-box label {
          display: none;
        }
        .product-item-comment {
          width: 100%;
          min-height: 150px;
          padding: 1rem;
          margin-top: 1.5rem;
          margin-bottom: 1.5rem;
        }
        .box-tocart {
          .field.qty {
            position: absolute;
            top: -3.6rem;
            right: 0;
            display: flex;
            align-items: center;
          }
          .control {
            margin: 0;
            margin-left: 1rem;
            input {
              width: 5rem;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1280px) {
    .page-wrapper .columns {
      justify-content: flex-end;
      .products.wrapper.grid.products-grid ol, widget.elementor-widget.product-grid ol {
        justify-content: flex-start;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .page-wrapper .columns {
      .products.wrapper.grid.products-grid ol, widget.elementor-widget.product-grid ol {
        justify-content: center;
      }
    }
  }
}
.customer-account {

  &-create {
    h1 {
      padding-bottom: 5rem;
    }

    .actions-toolbar {
      display: flex !important;
      width: 100%;
    }

    form#form-validate {
      @include flexWrap;
      flex-direction: row-reverse;
      justify-content: flex-end;

      .fieldset {
        width: 49%;
        &.info{
          margin-left: 2%;
        }
        legend.legend {
          @include fontLarger;
          font-weight: bold;
          padding-bottom: 1rem;
        }

        @media only screen and (max-width: 1280px) {
          width: 100%;
        }
      }
    }
  }
}

.additional {
  &_info{
    justify-self: flex-end;
  }
  &-addresses {
    table {
      position: relative;

      th.col.state, td.col.state, th.col.phone, td.col.phone {
        display: none;
      }

      td.col.actions {
        padding-right: 0;
      }
    }
  }
}

/*Login*/
.login-container {
  @include flexBetween;
  align-items: flex-start;

  .block {
    width: 49%;
    border: none;
    display: block;
    .primary {
      display: block;
    }
    .block-content {
      .actions-toolbar {
        margin-top: 1.5rem;
      }
    }
    form fieldset {
      label {
      }
    }
  }
}
/*Passwort*/
.password.required {

  .control {
    display: flex;
    flex-direction: column;
    #password {
      order: 1;
    }

    #password-error {
      color: $colorRed;
      margin: 0.5rem 0;
      order: 3;
    }

    #password-strength-meter-container {
      background-color: $colorRed;
      padding: 1rem 0 1rem 1rem;
      order: 2;
      color: $colorWhite;
      margin-bottom: 1rem;
      #password-strength-meter {
        line-height: 1;
      }
      &.password-very-strong {
        background-color: $colorSuccess;
      }
      &.password-strong {
        background-color: $colorSuccess;
      }
      &.password-medium {
        background-color: $colorInfo;
      }
      &.password-weak {
        background-color: $colorRed;
      }
      &.password-error {
        background-color: $colorRed;
      }
    }
  }
}

