input, select, textarea {
  @include fontSmaller;
  line-height: normal;
  background: $colorWhite;
  border: 1px solid $colorGray;
    width: 100%;
    padding: 1.1rem 0.9rem;
    color: $colorBlack;


}
select#country {
  min-height: 3.6rem;
}
fieldset {
  border: none;
  display: block;
}
.field.region {
  display: none;
}
img.captcha-img {
  height: 44px;
  margin-right: 1.5rem;
}
fieldset br {
  display: none;
}
table {
  border-spacing: 0 !important;
  width: 100%;
}
.account .choice {
  display: flex;
  flex-direction: row;
  align-items: center;
  span {

    display: block;
  }
}
input.checkbox {
  margin-right: 0.5rem;
  width: 1.3rem;
  height: 1.3rem;

}
.password-info {
  margin-bottom: 1.5rem;
  border: none;
  line-height: 2.6rem;
  display: block;
  font-size: 2rem;
}
/*Contact*/
form {
  .note {
    margin-bottom: 1.5rem;
    width: 100%;
  }
  fieldset {
    padding-bottom: 1.5rem;
    &:after {
      margin: 3.5rem 0 0;
      content: attr(data-hasrequired);
      color: $colorRed;
      font-size: 1.4rem;
    }

    .control {
      input, select {
        width: 100%;
        margin-bottom: 1.5rem;
      }
      input#captcha_user_login {
        text-transform: none;
      }
      textarea {
        width: 100%;
        padding: 1rem;
      }
    }
  }
}

/*neue adresse tooltip checkout*/


.control{
  &._with-tooltip{
    @include flexWrap;
    justify-content: space-between;
    position: relative;
    .field-tooltip {
      cursor: pointer;
      position: absolute;
      right: 7px;
      top: 0;
      &.toggle{
        #tooltip {
          &:before {
            @include fontIcon;
            font-weight: 400;
            font-size: 2rem;
            color: #333;
            content: "";
          }
          &-label {

            border: 0;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
          }
        }
      }
      &._active{
        .field-tooltip-content {
          display: block;
        }
      }
      &-content{
        background: $colorGray;
        border: .1rem solid $colorSecondary;
        border-radius: .1rem;
        font-size: 1.4rem;
        padding: 1.2rem;
        width: 27rem;
        display: none;
        margin-left: 4rem;
        position: absolute;
        text-transform: none;
        right: 3.5rem;
        bottom: 0;
        word-wrap: break-word;
        z-index: 2;
        color: $colorWhite;
      }
    }
    .input-text {
      -ms-flex-preferred-size: calc(100% - 4rem);
      flex-basis: calc(100% - 4rem);
    }
  }
}
fieldset{
  &.street {
    &.admin__control-fields {
      &.required {
        padding-bottom: 0;
        > .label {
          display: none;
          padding-bottom: 0;
        }
      }
    }
  }
}
#opc{
  &-new-shipping-address{
    .field{
      &.choice {
        @include flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        line-height: 1;
        align-items: center;
        label.label{
          line-height: 1;
        }
      }
    }
    #shipping-save-in-address-book{
      width: 25px;
      margin-bottom: 0;
    }
    input, select{
      width: 100%;

    }
  }
}
.field-error{
  color: $colorRed;
  font-size: 1.4rem;
}
.required > label, ._required > label {
  position: relative;
  &:after {
    @include fontIcon;
    content: "#{$iconAsterix}";
    color: black;
    position: absolute;
    top: 0;
    font-size: 6px;
    line-height: 1;
  }
}

.towishlist {
  position: absolute;
  right: 1.1rem;
  top:1.1rem;
  &:before {
    @include icon;
    width: 1.7rem;
    height: 1.7rem;
  }
}
.is_new {
  position: absolute;
  left: 1rem;
  top: 1rem;

  &:before {
    @include icon;
    width: 4.2rem;
    height: 4.2rem;
  }
}

.tocompare {
  &:before {
    content: "#{$iconCompare}";
    @include fontIcon;
    margin-right: 0.25rem;
  }
}
.field{
  &.captcha{
    padding: 1.7rem 2rem;
    background:rgba($colorPrimary, .15) ;
    margin: 2rem 0;
  }
}
.captcha {
  flex-wrap: wrap;


  label, .action{
    font-size: 1.4rem;

    &.reload{
      background: transparent;
    }
  }
  .captcha {
    width: 100%;
    padding: 0;
    background: transparent;
    margin: 0;
    &-image {
      @include flex;
    }
  }
}

.widget.elementor-widget.contact-form {
 margin-bottom: -12rem !important;
  .field-wrapper.half {
    @include flexBetween;
    width: 100%;
    @media only screen and (max-width: 768px) {
      flex-wrap: wrap;
      margin-bottom: 0 !important;
    }
    .field {
      width: 49%;
      @media only screen and (max-width: 768px) {
        width: 100%;
        margin-bottom: 3rem;
      }
    }
  }
  ul li.field-wrapper {
    width: 100% !important;
  }
  span.wpcf7-list-item {
    width: 100%;
    display: inline-flex;
    align-items: center;

  margin-right: 1rem;
    margin-left: 0;
    input[type="checkbox"] {
      width: 25px;
      text-align: left !important;
    }
  }
  .field.privacy-policy {
    label {
      display: block;
      a {
        text-decoration: underline;
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    margin-bottom: -6rem !important;
  }
}

.widget.elementor-widget.contact-form ul li:nth-child(5) {
  margin-top: 0 !important;
  margin-bottom: 3rem !important;
}

.form{
  &-discount{

    .action {
      width: 100%;
      margin: 1rem 0;
      font-size: 1.4rem;
      &s-toolbar {
        > * {
          width: 100%;
        }
      }
    }
  }
}