.page{
  &-header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 11;
    background: $colorWhite;
    &.scroll{
      .header{
        &-top{
          &-logo{
            .logo{
              padding: 1rem 0 1rem;
              img{
                max-width: 10rem;
              }
            }
          }
          &-shop{
            li, .minicart-wrapper{
              max-height: 4rem;
              overflow: hidden;
              a{
                .qty{
                  .counter-number{
                    top: 0;
                  }
                }
              }
            }
          }
          &-language{
            padding: 1rem 0 1rem 3rem;
          }
        }
        &-main{
          &-menu{
            nav{
              > ul{
                > li{
                  > a{
                    padding: 1.5rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.header{
  &-top{
    @include container;
    position: relative;
    &-container {
      @include flex;
      @include flexBetween;
      @include flexVerticalCenter;
    }
    &-logo{
      .nav-toggle {
        display: none;
      }
      .logo {
        display: block;
        padding: 22px 15px 22px 0;
        @include defaultFastTrans;
        img{
          max-width: 138px;
          @include defaultFastTrans;
        }
      }
    }
    &-cart, &-info{
      li, .minicart-wrapper{
        list-style: none;
        > a{
          padding: 0 15px;
          line-height: 20px;
          position: relative;
          @include defaultFastTrans;
          display: inline-block;
          width: auto;
          text-align: center;
          font-size: 12px;
          &:before{
            display: block;
            width: auto;
            margin-top: 1rem;
            content: url($imageDir + "icons/user.svg");
            max-height: 30px;
            @include defaultFastTrans;
          }
        }
      }
    }
    &-cart{
      .minicart-wrapper{
        a{
          &:before{
            content: url($imageDir + "icons/cart.svg");
            margin-left: -8px;
          }
        }
      }
    }
    &-info{
      width: 100%;
      ul{
        @include flex;
        justify-content: flex-end;
      }
      li{
        list-style: none;
        &:nth-child(2){
          a{
            &:before{
              content:url( $imageDir + "icons/blog.svg");
            }
          }
        }
        &:first-child{
          a{
            &:before{
              content:url( $imageDir + "icons/phone.svg");
            }
          }
        }
      }
      > *{
        > a {

        }
      }
    }
    &-language{
      padding:20px 0 20px 30px;
      border-left: 1px solid #c5c5c5 ;
      @include defaultFastTrans;
      .switcher {
        &-label {
          display: none;
        }
        .options {
          position: relative;
          .actions{
            &.toogle{
              cursor: pointer;
              display: inline-block;
              text-decoration: none;
            }
          }
          ul.dropdown{
            margin-top: 0.4rem;
            z-index: 100;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            display: none;
            position: absolute;
            top: 100%;
            left: 0;
          }
          &.active {
            overflow: visible;
            ul.dropdown{
              display: block;
            }
            .switcher-trigger{
              &:after{
                transform: rotate(-180deg);
              }
            }
          }
        }
        &-options{
          .switcher-trigger{
            @include flex;
            @include flexCenter;
            &:after{
              content: "\f078";
              @include fontIcon;
              font-size: 16px;
              margin-left: 10px;
              transition: all ease .2s;
            }
          }
          strong.view{
            &-de, &-en{
              font-size: 0;
              line-height: normal;
            }
            &-de{
              &:after{
                content: url($imageDir + "icons/germany.png");
              }

            }
            &-en{
              &:after{
                content: url($imageDir + "icons/united-kingdom.png");
              }

            }
          }
          li.view{
            &-de, &-en{
              font-size: 0;
              line-height: normal;
              background: transparent;
              a{
                line-height: normal;
                display: inline-block;
                background: transparent;
              }
            }
            &-de{
              a{
                &:after{
                  content: url($imageDir + "icons/germany.png");
                }

              }
            }
            &-en{
              a{
                &:after{
                  content: url($imageDir + "icons/united-kingdom.png");
                }

              }
            }
          }
        }
      }
    }
  } /* header-top */
  &-main {
    background: $colorSecondary;
    position: relative;

    &-container {
      @include flexBetween;
      @include flexVerticalCenter;
      @include container;

    }
    &-menu {
      width: 100%;
      nav{
        ul{
          &.level {
            &1 {
              top: 0 !important;

              li {
                a {
                  font-weight: 400;
                }
              }
            }
            &2, &3, &4{
              top: 0 !important;
            }
          }
        }
        > ul{
          width: 100%;
          @include flex;
          @include flexVerticalCenter;
          margin: 0 auto;
          margin-left: -15px;

          > li{
            text-align: center;
            width: auto;
            position: relative;
            &:hover{
              > ul{
                display: block;
              }
            }
            > a{
              @include fontHeadline;
              color: $colorWhite;
              text-transform: uppercase;
              justify-content: center;
              align-items: center;
              width: 100%;

              font-size: 18px;
              padding: 30px 15px;
              font-weight: 800;
              display: flex;
              flex-direction: row-reverse;
            }
            > ul{
              position: absolute;
              padding: 10px 0;
              min-width: 270px;
              background: $colorPrimary;
              top: 100%;
              z-index: 2;
              flex-wrap: wrap;
              text-align: left;
              display: none;

              li{
                position: relative;
                &:hover{
                  > ul{
                    display: block;
                  }
                }
                ul{
                  position: absolute !important;
                  left: 100% !important;
                  top: 0;
                  min-width: 270px;
                  background: $colorPrimary;
                  display: none;
                }
                a{
                  &:before{
                    content: $iconArrowNext;
                    @include fontIcon;
                    margin-right: 13px;
                  }
                  font-size: 16px;
                  text-transform: none;
                  color: $colorWhite;
                  display: inline-block;

                  width: 100%;
                  padding: 5px 20px;

                  span{
                    @include robotoSlab;
                    font-weight: bold;
                  }
                }
              }
            }
            &.parent{
              > a{
                &:before{
                  content: "\f078";
                  @include fontIcon;
                  color: $colorWhite;
                  font-size: 16px;
                  margin-left: 15px;
                }
                .ui-menu-icon{
                  display: none;
                }
              }
            }

            @media only screen and (min-width: #{$mediumContentBreakpoint}) {
            &:hover {
              a {
                background: $colorPrimary;
              }
            }
              > ul{

              }
            }
          }
        }
      }
    } /* header-main-menu */
    &-info{
      .block {
        &-title {

          padding-right: 15px;
          cursor: pointer;
          > strong{
            font-size: 0;
            padding: 0;
            display: block;
            line-height: normal;
            &:before{
              content: url($imageDir + "icons/search.svg");
              line-height: normal;
              display: inline-block;
            }
          }
        }
        &-content{
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          z-index: 3;
          background: $lightGray;
          display: none;
          form{
            @include container;
            @include flex;
            padding-top: 65px;
            padding-bottom: 50px;
            .search{
              vertical-align: top;
              width: 100%;
              height: 100%;
              margin-right: 10px;
              > *{
              }
              input{
                width: 100%;
                border:0;
                height: 100%;
                border-bottom: 2px solid rgba(0,0,0,0.7);
                background: transparent;
                padding: 20px 0;
                font-size: 20px;
              }
              label{
                display: none;
              }
            }
            .actions{
              button{
                background: transparent;
                border: 2px solid $colorSecondary;
                padding: 20px 50px;
                font-size: 14px;
                text-transform: uppercase;
                line-height: normal;
                margin: 0;
              }
            }
          }
          &.active{
            display: block;
          }
        }
        &-search{
          .block-title{
            border:0;
            margin: 0;
            padding: 0;
          }

        }
      }
    }
    &-search{

    }
  } /* header-main */
}
.mm{
  &enu{
    &-top {
      &-language {
        order:2;
        padding: 2.3rem 0;
        border-left: 1px solid $colorTertiary;

        &.active{
          .switcher-dropdown{
            max-height: 100vh;
          }
        }
        .switcher {
          position: relative;
          &-label {
            display: none;
          }

          &-dropdown {
            max-height: 0;
            overflow: hidden;
            position: absolute;
            left: -1px;
            width: 100%;
            height: auto;
            top: 100%;

            background: $colorSecondary;
            border-left: .1rem solid $colorTertiary;

            @include defaultFastTrans;
            li{
              padding-top: 2.3rem;
              padding-bottom: 2rem;
              border-bottom: .1rem solid $colorTertiary;
              border-right: 1px solid $colorBorder;
            }
          }
        }
        strong {
          &.view {
            &-de {

              &:after {
                content: url($imageDir + "icons/germany.png");
              }

            }

            &-en {

              &:after {
                content: url($imageDir + "icons/united-kingdom.png");


              }
            }
          }
        }
        .view {
          &-de, &-en {
            font-size: 0;
            line-height: normal;
          }

          &-de {
            a, strong{
              display: inline-block;
              &:after {
                content: url($imageDir + "icons/germany.png");
              }
            }


          }

          &-en {
            a, strong{
              display: inline-block;
              &:after {
                content: url($imageDir + "icons/united-kingdom.png");
              }
            }

          }
        }
      }
      &-search{
        @include flexCenter;
        .block{
          &-title{
            display: block;
            cursor: pointer;
            strong{
              font-size: 0;
              padding: 0;
              display: block;
              line-height: normal;
              &:before{
                content: url($imageDir + "icons/search.svg");
                line-height: normal;
                display: inline-block;
              }
            }
          }
          &-content{
            display: none;
            position: absolute;
            width: 100%;
            left: 0;
            &.active{
              display: block;
            }
            form{
              @include flex;
              margin-top: 2.2rem;
              padding: 2rem 1rem;
              background: $lightGray;
              .search{
                width: 100%;
              }
              input{
                border:0;
                background: transparent;
                border-bottom: 1px solid $colorBlack;
              }
              .label{
                display: none;
              }
              .actions{
                @include flexCenter;
              }
              button{
                border:0;
                background: transparent;
                font-size: 0;
                &:after{
                  content: url($imageDir + "icons/search_black.png");
                  line-height: normal;
                  display: inline-block;
                }


              }
            }
          }
        }
      }
    }
  }
  &-menu{
    background: $colorSecondary;
    .mm{
      &-listitem {
        border:0;
        &_opened{
          > .mm-btn_next{
            background: transparent;
          }
        }
        &:after{
          display: none;
        }
        .mm-btn_next{
          padding: 0;
          height: 6rem;
          &:before{
            display: none;
          }
          &:after {
            border-color: $colorWhite;
          }
        }
        > a {
          color: $colorWhite;
          font-size: 2rem;

          @include robotoSlab;
          padding: 1.5rem 2rem;
        }
        .mm{
          &-panel{
            background: $colorPrimary;
            padding: 0;
            .mm-listitem{
              flex-direction: row-reverse;
              .mm-btn_next{
                margin-left: 0;
                left: 0;
              }
              > a{
                padding: 2rem 2rem;
                margin-right: 0;
                margin-left: 5rem;
              }
              .mm-panel{
                padding: 0 2rem;
              }
            }
          }
        }
      }
    }

  }
  &-navbars{
    &_top{
      .mm{
        &-navbar{
          height: auto;
          border-bottom: 1px solid $colorTertiary;
        }
      }
    }
  }
  &-panels{
    .mm-navbar{
      display: none !important;
    }
  }
}
.navigation-header{
  display: none;
}
