.catalog-product-view {
  .action-skip-wrapper{
    display: none;
  }
  .column {
    @include flexWrap;
    @include flexBetween;
    .product{
      &.media, &-info-main {
        width: 49%;
        position: relative;
      }
      .wishlist{
        a{
          display: inline-block;
          width: auto;
          border: 2px solid $colorPrimary;
          color: $colorPrimary;
          text-transform: uppercase;
          font-weight: 800;
          font-size: 14px;
          padding: 5px 15px;
        }
      }
      &-add-form{
        padding-top: 30px;
        .box-tocart{
          .fieldset{
            .control{
              width: 100%;
              margin-top: 5px;
              input{
                width: auto;
                &.qty{
                  text-align: center;
                  width: 5rem;
                  height: 5rem;
                }
              }
            }
            .actions{
              margin-top: 20px;
            }
          }
        }
      }
      &-info {
        width: 100%;
        &-main {
          .page-title {
            text-align: left;
            font-size: 5rem;
            line-height: 6.2rem;
            padding: 0;
            padding-bottom: 30px;
            &-wrapper {
              padding: 0;
            }
          }
          .price {
            &-box {
              color: $colorPrimary;
              font-weight: 800;
              font-size: 24px;
              line-height: 29px;
            }
            &-details {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
.product{
  &-options-bottom{
    margin-top: 2rem;
  }
}