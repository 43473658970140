.page-layout-2columns-left{
  .columns{
    .column{
      padding-left: 50px;
      width: calc(75% - 8px);
    }
  }
}
.page-products{
  .columns{
    padding-top: 8rem;
  }
}
.page-title-wrapper{
  padding: 2rem 0;
}
.product{
  &s{
    padding: 2.5rem 0;
  }
  &-image, &-item-info{
    a {
      display: inline-block;
      width: 100%;
      text-align: center;
      .product {
        &-image-container {
          display: inline-block;
        }
      }
    }
  }
  &-image{
    a{
      @include flex;
      justify-content: center;
    }
    &-container, &-wrapper{
      width: 100%;
      img{
        width: 100%;
        height: auto;
      }
    }
  }
  &-item{
    width: calc(33.333% - 15px);
    text-align: center;
    padding: 20px 0;
    margin-top: 15px;
    margin-bottom: 15px;
    position: relative;
    margin-right: 15px;
    &:nth-of-type(3n){
      margin-right: 0;
    }
    .price {
      &-box {
        color: $colorPrimary;
        font-weight: 800;
        font-size: 24px;
        line-height: 29px;
        margin-top: 2.6rem;
        justify-content: center;
        flex-direction: row-reverse;
        .price{
          line-height: normal;
        }
        .price-label{
          display: none;
        }
        .old-price{
        }
        .normal-price{
          .price {
            &-container{
              @include flex;
             align-items: flex-end;
            }
            &-label {
              margin-right: 1rem;
              font-size: 1.8rem;
              font-weight: normal;
              line-height: normal;
              color: $colorSecondary;
            }
          }
        }
      }
      &-details{
        font-size: 14px;
      }
    }
    &:hover{
      .product-item-inner {
        opacity: 1;
      }
    }
    &s{
      &:not(.splide__list) {
        width: 100%;
        @include flexWrap;
      }
    }
    &-actions{
      .product-item-inner-wrapper{
        padding-top: 30px;
        @include flex;
        justify-content: center;
        a{
          width: auto;
        }
      }
      a{
        padding: 0 20px;
        display: inline-block;
        width: auto;
        background: transparent;
        border: 3px solid $colorPrimary;
        color: $colorPrimary;
      }
    }
    &-link{
      text-transform: uppercase;
      font-size: 14px;
      width: auto;
      font-weight: bold;
      &:hover{
        border:3px solid $colorPrimary;
        box-shadow: none;
        background: $colorPrimary;
        color: $colorWhite;
      }
    }
    &-inner{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(255,255,255,0.8);
      @include flexWrap;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: all ease .2s;
      > *{
        width: 100%;
      }
      &-wrapper{
        font-size: 18px;
        textarea{
          width: 100%;
          @include montserrat;
          font-size: 18px;
          padding: 10px;
        }
        .box-tocart{
          input[type="number"]{
            width: auto;
            max-width: 148px;
          }
          .product-item-actions{
            margin-top: 20px;
          }
        }
      }
      a.towishlist{
        display: block;
        margin-top: 15px;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 800;
      }
    }
    &-info{
      &-main{
        .product-add-form{
          form{
            .product-options-bottom{
              padding-top: 20px;
            }
          }
        }
      }
    }
    &-name{
      display: inline-block;
      width: 100%;
      padding-top: 2.8rem;
      a{
        @include robotoSlab;
        font-size: 2.2rem;
        font-weight: bold;
      }
    }
    &-new{
      position: absolute;
      left: 0;
      top: 0;
      text-transform: uppercase;
      color: $colorWhite;
      z-index: 3;
      font-size: 1.8rem;
      &:after{
        position: absolute;
        left: 0;
        top: 0;
        -webkit-transform: perspective(70px) rotateX(-20deg);
        -webkit-transform-origin: left center;
        background-color: $colorGreen;
        width: 100%;
        content: "";
        height: 100%;

        padding-bottom: 55%;
      }
      span{
        display: inline-block;
        padding: .7rem 1rem;
        position: relative;
        padding-right: 3rem;
        z-index: 1;
        font-weight: bold;
        font-style: italic;
      }
    }
  } /* product-item*/
  &-add{
    &-form{
      .swatch-opt{
        .swatch {
          &-attribute {
            margin-top: 20px;
            &:first-child{
              margin-top: 0;
            }
            &-selected-option {
              display: none;
            }
            &-options {
              @include flex;
              padding-top: 10px;
            }
          }
          &-option {
            min-width: 50px;
            min-height: 50px;
            margin-right: 10px;
            opacity: 0.6;
            transition: all ease .6s;
            padding: 0 1rem;
            cursor: pointer;
            &.text {
              @include flexCenter;
              border: 2px solid $colorSecondary;
            }
            &.color {
              -webkit-border-radius: 50px;
              -moz-border-radius: 50px;
              border-radius: 50px;
            }
            &:hover {
              opacity: 1;
            }
            &.selected {
              opacity: 1;
            }
          }
        }
        input{
          display: none;
        }
      }
    }
  }/* product-add */
  &.info {
    &.detailed {
      padding-top: 50px;
      .items {
        position: relative;
        .data.item {
          &.title {
            float: left;
            width: auto;
            padding-bottom: 0;
            margin-right: 5px;
            border-bottom: 0;
            font-size: 1.8rem;
            line-height: normal;
            font-weight: normal;
            a{
              display: inline-block;
              padding: 10px 20px;
              border: 1px solid $colorSecondary;
              border-bottom: 0;
              line-height: normal;
            }
            &.active{
              position: relative;
              z-index: 2;
              a{
                color: $colorRed;
                border-color: $colorPrimary;
                border-bottom: 2px solid $colorWhite;
              }
            }
          }
          &.content{
            float: right;
            margin-left: -100%;
            width: 100%;
            margin-top: 4.3rem;
            position: relative;
            border: 1px solid $colorPrimary;
            padding: 20px;
            table{
              width: 100%;
              text-align: left;
              caption{
                text-align: left;
                font-weight: 800;
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }
  }
}
.wishlist-toolbar{
  @include flexWrap;
  .toolbar-amount{
    width: 100%;
  }
  .limiter{
    padding-right: 15px;
    border-right:1px solid #c5c5c5 ;
  }
  .limiter, .toolbar-sorter{
    width: auto;
    margin-right: 15px;
    .sorter-action, .limiter-text{
      font-size: 14px;
    }
  }
}
.wishlist-toolbar{
  .limiter{
    border-right: 0;
  }
}
.sidebar {
  width: calc(25% - 8px);
  &-additional {
    display: none;
  }
  .block{
    &-title{
      @include robotoSlab;
      color: $colorPrimary;
      border-bottom: 2px solid $colorSecondary;
      padding-bottom: 7px;
      margin-bottom: 7px;
    }
    &-content{
      font-size: 18px;
      .filter {
        &-options {
          font-size: 14px;
          &-title{
            font-weight: 800;
            margin-top: 15px;
            margin-bottom: 7px;
          }
          &-content{
            .swatch-attribute{
              .swatch-attribute-options{
                @include flex;
                a.swatch-option-link-layered{
                  width: auto;
                  margin-right: 5px;
                  display: inline-block;
                  > div{
                    min-width: 35px;
                    min-height: 30px;
                    border: 2px solid $colorSecondary;
                    text-align: center;
                    &.color{
                      min-width: 30px;
                      width: 30px;
                      height: 30px;
                      border:0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &-subtitle{
      margin-bottom: 20px;
    }
  }
}
.toolbar{
  &-products {
    background: $lightGray;
    @include flex;
    position: relative;
    z-index: 2;
    margin-bottom: 2.5rem;
    .modes{
      height: 100%;
      strong, a{
        height: 100%;
        padding: 10px 13px;
        border-right: solid 1px #dfdfdf;
        display: inline-block;
      }
      #modes-label{
        display: none;
      }
      &-mode{
        span{
          display: none;
        }
        &:before{
          @include fontIcon;
          color: #909090;
        }
        &.mode{
          &-list{
            &:before{
              content: "\f03a";
            }
          }
          &-grid{
            &:before{
              content: "\f00a";
            }
          }
        }
        &.active{
          &:before{
            color: $colorPrimary;
          }
        }
      }
    }
  }

  select, .sorter-trigger{
    @include fontDefault;
    font-size: 1.8rem;
    height: 100%;
    color: $colorFont;
    padding: 0;
    background: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0;
    position: relative;
    option{
      @include fontDefault;
      font-size: 1.6rem;
    }
    &:after {
      @include fontIcon;
      position: absolute;
      content: "\f0dd";
      top: -4px;
      right: 0;
    }
  }
  &-amount {
    flex-grow: 1;
    margin-bottom: 0;
    height: 100%;
    padding: 10px 13px;
    border-right: solid 1px #dfdfdf;
    color: $colorPrimary;
    text-align: left;
    width: 100%;
  }
  .limiter, &-sorter{
    @include flex;
    height: 100%;
    padding: 10px 13px;
    border-right: solid 1px #dfdfdf;
    flex-shrink: 0;
    label{
      padding-right: 20px;
    }
  }
  .limiter{
    &-text{
      display: none;
    }
  }
  &-sorter{
    label{
      display: none;
    }
    select{
      min-width: 160px;
    }
    a{
      &:before{
        @include fontIcon;
        color: #909090;
      }
      &.sort{
        &-asc{
          &:before{
            content: "\f30c";
          }
        }
        &-desc{
          &:before{
            content: "\f309";
          }
        }
      }
      span{
        display: none;
      }
    }
  }
  .switcher {
    padding: 0;
    &-options {
      position: relative;
      width: 100%;
      padding: 10px 20px;
      border-right: solid 1px #dfdfdf;
      &.active{
        .switcher-dropdown{
          max-height: 500px;
        }
      }
    }
    &-trigger{
      strong{
        font-weight: 500;
      }
    }
    &-dropdown{
      position: absolute;
      max-height: 0;
      overflow: hidden;
      top: 100%;
      background: $lightGray;
      left: 0;
      width: 100%;
      @include defaultFastTrans;
      li{
        padding: 18px 20px 17px;
        border: solid 1px #dfdfdf;
        border-bottom: solid 0 #dfdfdf;
        line-height: normal;
        a{
          color: #777;
          padding: 0;
          font-size: 1.6rem;
        }
        &:last-child{
          border: solid 1px #dfdfdf;
        }
      }
    }
  }
} /* end toolbar */
.category {
  &-view {
    max-height: calc(100vh - 12rem);
    overflow: hidden;
  }
  &-image {
    img {
      width: 100%;
    }
  }
}
.pages{
  width: 100%;
  padding: 10px 13px;
  &-label{
    display: none;
  }
  ul{
    @include flex;
    li{
      margin-right: 10px;
      strong, a{
        padding: 5px 10px;
        display: inline-block;
        line-height: normal;
        &.action{
          display: inline-block;
          padding: 0 1rem;
        }
        span{
          &.label{
            display: none;
          }
        }
      }
      &.current{
        strong{
          background: $colorPrimary;
          color: $colorWhite;
        }
      }
      &.pages-item-previous{
        a{
          margin: 0 !important;
          padding: 0 !important;
          .label{
            display: none;
          }
        }
      }
      &.pages-item-next{
        a{
          margin: 0 !important;
          padding: 0 !important;
          .label{
            display: none;
          }
        }
      }
    }
  }
}
.toolbar-products .pages {
  display: none;
}
.products.wrapper~.toolbar-products {
  .pages {
    display: block;
  }
  .toolbar-sorter{
    display: none;
  }
}