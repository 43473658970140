// width
$maxWidth: 192rem;
$contentWidth: 140rem;
$mediumContent: 102.4rem;
$smallContent: 65rem;
$outerWidth: 153rem;

// breakpoints
$contentWidthBreakpoint: 1400px;
$mediumContentBreakpoint: 1051px;
$mediumSmallBreakpoint: 768px;
$smallContentBreakpoint: 650px;
$mobileBreakpoint: 480px;

// images
$imageDir: "../../images/";
// icons
$iconDir: "../../images/icons/";
// images Responsive
$imageDirResp: "../images/";

// colors + shades
$colorWhite: #fff;
$colorBlack: #000;
$colorPrimary: #e30613;
$colorSecondary: #333;
$colorTertiary:#707070;
$colorBorder: #333;
$colorGray: #484848;
$colorInfo: #F7B234;
$colorWarning: #F7B234;
$colorButton: #e30613;
$colorFont: #333;
$colorError: #c1121c;
$colorSuccess: #0D62AB;
$lightGray: #eceeef;
$colorRed: #c12a2a;
$colorLighterGrey: #F7F7F7;
$colorGreen:#00BE84;


// icons: use fontawesome pro unicode - leave empty for no icon
$iconCart: "\f290";
$iconWishlisht: "\f004";
$iconCompare: "\f080";
$iconClose: "\f00d";
$iconTrash: "\f1f8";
$iconEdit: "\f304";
$iconGrid: "\f009";
$iconList: "\f03a";
$iconArrow: "\f077";
$iconArrowNext: "\f054";
$iconPagination: "\f054";
$iconCheck: "\f00c";
$iconAsterix: "\f069";
$iconQuestion: "\f059";
$iconError: "\f06a";
$iconInfo: "\f05a";
$iconWarning: "\f071";
$iconToggle: "\f0c9";
$iconSearch: "\f002";
$iconSortAsc: "\f160";
$iconSortDesc: "\f161";
$iconKey: "\f084";
$iconPerson: "\f007";

@mixin container {
    width: 100%;
    max-width: $contentWidth;
    margin: 0 auto;
    padding: 0 15px;
    @media only screen and (max-width: #{$contentWidthBreakpoint}) {
        max-width: $mediumContent;
    }
    @media only screen and (max-width: #{$mediumContentBreakpoint}) {
        max-width: $smallContent;
    }
    @media only screen and (max-width: #{$mediumSmallBreakpoint}) {
        max-width: 90%;
        padding: 0;
    }
}
@mixin smallContainer {
    max-width: $mediumContent;
    margin: 0 auto;
    padding: 0 15px;
}
@mixin flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
@mixin flexVertCenter {
    @include flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
@mixin flexColumn {
    @include flex;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
}
@mixin defaultFastTrans {
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
}
@mixin icon {
    display: inline-block;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    height: 3rem;
    width: 3rem;
    font-size: 3rem;
    color: #000;
    content: '';
}
@mixin flexVerticalCenter {
    @include flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
@mixin flexCenter {
    @include flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
@mixin flexStart {
    @include flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}
@mixin flexEnd {
    @include flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}
@mixin flexWrap {
    @include flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
}
@mixin flexBetween {
    @include flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

