.cms-home{
    .column {
        width: 100%;
        padding: 0;
        &s {
            padding: 0;
            max-width: inherit;
            width: 100%;
        }
    }
    .webcrossing-base {
        &-imageslider-slider {
          .splide__track,
          .splide__list {
            max-width: 1920px;
            width: 100%;
            margin: 0 auto;
          }
            li {
                position: relative;
                .block{
                    &-title{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        text-align: left;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        &-sub{
                            @include container;
                            padding: 0 15px;
                            .sub-headline{
                                @include montserrat;
                                font-weight: 800;
                                font-size: 16px;
                                color: #fff;
                                background: $colorSecondary;
                                padding: 16px 20px;
                                font-style: italic;
                                width: auto;
                                display: inline-block;
                                position: relative;
                                line-height: 18px;
                                &:after{
                                    position: absolute;
                                    left: 100%;
                                    height: 100%;
                                    top: 0;
                                    width: auto;
                                    content: url($imageDir + "icons/diagonal.png");
                                }
                            }
                            h2{
                                display: block;
                                width: 100%;
                                text-align: left;
                                margin: 30px 0;
                                max-width: 800px;
                                color: $colorWhite;
                                font-size: 65px;
                                line-height:74px;
                            }
                            .text{
                                display: block;
                                font-size: 24px;
                                font-weight: 800;
                                color: $colorWhite;
                            }
                            .link{
                                display: inline-block;
                                padding: 15px 30px;
                                background: $colorPrimary;
                                color: $colorWhite;
                                font-size: 14px;
                                width: auto;
                                font-weight: 800;
                                @include montserrat;
                                margin-top: 25px;
                            }
                        }
                    }
                }
            }
            .splide{
                &__slide{
                    img{
                        width: 100%;
                    }
                }
                &__arrows{
                    button{
                        &.splide__arrow{
                            > *{
                                display: none;
                            }
                            &--prev, &--next{
                                background: transparent;
                                &:before{
                                    width: 100%;
                                    height: auto;
                                }
                            }
                            &--prev{
                                &:before{
                                    content: url($imageDir + "icons/arrow_left_white.svg");
                                }
                            }
                            &--next{
                                &:before{
                                    content: url($imageDir + "icons/arrow_right_white.svg");
                                }
                            }
                        }
                    }
                }
            }
        } /* .webcrossing-base-imageslider-slider */
        &-product-slider{
            @include container;
            @include flex;
            @include flexWrap;
            padding-bottom: 60px;
            > *{
                width: 100%;
            }
            .block-title{
                text-align: left;
                > *{
                    text-align: left;
                    border-bottom: 3px solid $colorSecondary;
                }
            }
            .splide {
                &__arrow{
                    > *{
                        display: none;
                    }
                    &--prev, &--next{
                        background: transparent;
                        &:before{
                            width: 100%;
                            height: auto;
                        }
                    }
                    &--prev{
                        left: 0;
                        &:before{
                            content: url($imageDir + "icons/arrow_left_black.svg");
                        }
                    }
                    &--next{
                        right: 0;
                        &:before{
                            content: url($imageDir + "icons/arrow_right_black.svg");
                        }
                    }
                }
            }
            .price{
                &-final_price{
                    color: $colorPrimary;
                    font-weight: 800;
                    font-size: 24px;
                    line-height: 29px;
                    margin-top: 30px;
                }
                &-details{
                    font-size: 14px;
                }
            }
        }/* .webcrossing-base-product-slider */
        &-category-popular{
            margin-bottom: 100px;
            padding-top: 7rem;
            .block{
                &-content{
                    @include flex;
                    @include flexWrap;
                    @include flexBetween;
                    > .image{
                        width: 31.333%;
                        margin: 1%;
                        position: relative;
                        margin-bottom: 20px;
                        .text{
                            position: absolute;
                            width: 100%;
                            height: auto;
                            vertical-align: middle;
                            text-align: center;
                            top: 50%;
                            left: 0;
                            transform: translate(0,-50%);
                            > *{
                                display: inline-block;
                                vertical-align: middle;
                                color: $colorWhite;
                            }
                            h2{
                                width: 100%;
                                margin-bottom: 0;
                                transition: all ease .2s;
                            }
                            a{
                                max-height: 0;
                                overflow: hidden;
                                background: $colorPrimary;
                                color: $colorWhite;
                                padding: 10px 18px;
                                display: inline-block;
                                transition: all ease .2s;
                                opacity: 0;
                            }
                        }
                        &:hover{
                            .text{
                                h2{
                                    padding-bottom: 20px;
                                }
                                a{
                                    max-height: 100px;
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }/* .webcrossing-base-category-popular */
        &-image-single{
            margin-top: 100px;
            .block {
                &-content {
                    position: relative;
                    overflow: hidden;
                    img{
                        width: 100%;
                    }
                }
            }
            &.layout{
                &-01{
                    .text{
                        @include container;
                        text-align: center;
                        z-index: 1;
                        position: absolute;
                        top: 0;
                        left: 50%;
                        width: 100%;
                        height: 100%;
                        transform: translate(-50%, 0);
                        .links{
                            position: absolute;
                            right: 20%;
                            top: 27%;
                        }
                    }
                }
                &-02{
                    width: 90%;
                    max-width: $contentWidth;
                    margin: 0 auto;
                    margin-top: 100px;
                    .block{
                        &-content{
                            .image {
                                position: absolute;
                                left: 0;
                                width: auto;
                                height: auto;
                                min-width: 100%;
                                min-height: 100%;
                                top: 0;
                                background: no-repeat center center;
                                background-size: cover;
                            }
                            .text {
                              height: auto;
                              padding: 0 0 0 50%;
                              position: absolute;
                              width: 100%;
                              z-index: 2;
                              top: 50%;
                              transform: translate(0, -50%);
                                h2 {
                                    color: $colorWhite;
                                    font-size: 34px;
                                    font-weight: 800;
                                    @include robotoSlab;
                                    text-align: left;
                                }
                                .links {
                                    a {
                                        margin-bottom: 10px;
                                        display: flex;
                                        padding: 8px 0;
                                        width: 100%;
                                        background: $colorPrimary;
                                        align-items: center;
                                        color: $colorWhite;
                                        @include robotoSlab;
                                        font-weight: 800;
                                        &:before {
                                            content: url($imageDir + "icons/pin.svg");
                                            padding: 25px 35px;
                                            width: 100px;
                                            border-right: 1px dashed $colorWhite;
                                            margin-right: 30px;

                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }/* .webcrossing-base-image-single */
        &-formular-newsletter{
            margin-top: 100px;
            padding: 35px 0 50px;
            background: url($imageDir + "icons/in.png") no-repeat right -47px bottom -63px #eceeef;
            text-align: center;
            .block {
                &-header {
                    h2 {
                        &:before {
                            display: block;
                            content: url($imageDir + "icons/letter_open_black.svg");
                            line-height: normal;
                            text-align: center;
                            margin-bottom: 15px;
                        }
                    }
                }
                &-content{
                    @include container;
                    .text{
                        max-width: $smallContent;
                        margin: 0 auto;
                        font-size: 18px;
                        display: inline-block;
                        margin-bottom: 30px;
                    }
                }

            }
            .form {
                display: flex;
                font-size: 14px;
                width: 100%;
                .actions{
                    width: 40%;
                    button{
                        background: transparent;
                        font-size: 14px;
                        text-transform: uppercase;
                        color: $colorBlack;
                        border: 2px solid $colorBlack;
                        border-left: 0;
                        position: relative;
                        margin: 0;
                        height: 100%;
                        width: 100%;
                        font-weight: 800;
                    }
                }
            }
            .newsletter{
                width: 100%;
                input{
                    width: 100%;
                    background: transparent;
                    line-height: 56px;
                    padding: 0 15px;
                    border: 2px solid $colorBlack;
                }
            }
            .social{
                ul{
                    @include flexCenter;
                    padding: 50px 0 0;
                    padding-right: 30px;
                    li{
                        width: auto;
                        a, span{
                            display: inline-block;
                            padding: 15px;
                            line-height: normal;
                            i{
                                &.fa-facebook-f{
                                    &:before{
                                        content: url($imageDir + "icons/facebook.svg");
                                    }
                                }
                                &.fa-instagram{
                                    &:before{
                                        content: url($imageDir + "icons/instagram.svg");
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }/* .webcrossing-base-formular-newsletter */
    }
}