input[type="number"] {
  -moz-appearance: textfield;
}
.title {
  font-weight: bold;
  line-height: 1;
  font-size: 2.4rem;
  padding-bottom: 1.6rem;
  display: block;
  border-bottom: 1px solid $colorSecondary;
}
.cart {
  &-price {
    font-weight: bold;
    font-size: 2rem;
    line-height: 2rem;
    letter-spacing: 0;
  }
  &-sidebar{
    width: 36rem;
    margin-top: 2.8rem;


  }
  &-membership{
    padding: 2.1rem 3rem;
    background: $colorSecondary;
    position: relative;
    margin-top: 5rem;
    font-size: 1.6rem;
    color: $colorWhite;
    h3{
      text-align: left;
      font-size: 2rem;
    }
    .action{
      &-edit{
        &:before{
          color: $colorWhite;
        }
      }
    }
    &:after{
      position: absolute;
      width: 8rem;
      height: 9rem;
      color: $colorWhite;
      bottom: 0;
      right: 0;
      content: "";
    }
  }
  &-container {
    @include flexStart;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row-reverse;
    #gift-options-cart {
      display: none;
    }
    form {
      &.form-cart{
        width: calc(100% - 42rem);
      }
      .actions {
        padding-top: 2rem;
        @include flexEnd;
        .action {
          margin-left: 2rem;
          border: 1px solid $colorSecondary;
          margin-right: 0;
          width: 33%;
          text-align: center;
          color: #4B5458;
          font-size: 1.4rem;
          &.continue {
            margin-left: 0;
          }
        }
        &-toolbar {
          align-items: center;
          .towishlist span {
            display: none;
          }
        }
        &-edit {
          display: none;
        }
      }
    }
    .shipping {

    }

    caption.table-caption {
      display: none;
    }
  }

  &-summary {
    border: 1px solid $colorLighterGrey;
    padding: 2.1rem 3rem;
    background: $colorLighterGrey;
    .load{
      &ing-mask {
        position: absolute !important;
        background: rgba($colorLighterGrey, .9);
        margin: 0;
      }
    }
    .checkout{
      margin-top: 1.6rem;
      .action{
        margin-top: 0;
        margin-right: 0;
      }
    }
    button, .primary {
      width: 100%;
    }
    .block {
      &.discount {
        cursor: pointer;
        margin-top: 1.6rem;
        color: $colorPrimary;
        font-size: 1.6rem;
        padding-bottom: 2rem;
        .title{
          border-bottom: 0;
        }
        .field {
          margin-bottom: 1.5rem;
        }

        .fieldset.coupon {
          label {
            display: none;
          }
        }

        .actions-toolbar {
          width: 100%;
          position: relative;
          left: inherit;
          top: inherit;
        }
      }
    }
  }

  &-totals {
    padding: 1.6rem 0 0;
    border-bottom: 1px solid $colorSecondary;
    .table {
      &-caption {
        text-align: right;
      }

      &.totals {
        width: 100%;

        .totals-tax {
          padding-top: 0;
          color: rgba(0, 0, 0, 0.5);
        }

        th, td {
          line-height: 1;
          padding: 0;
          font-size: 1.6rem;
          padding-bottom: 1.6rem;
          font-weight: normal;
        }

        .sub {

        }
        th.mark {
          text-align: left;
        }

        td.amount {
          text-align: right;
        }
      }

      &-wrapper {
        tr.grand.totals {
          color: black;
        }
        .load.indicator {
          background-color: $colorLighterGrey;
          z-index: 9999;
          bottom: 0;
          left: 0;
          right: 0;
          top: 0;
          position: absolute;
          &:before {
            background: transparent url($imageDir + "loader.gif") no-repeat 50% 50%;
            border-radius: 0.5rem;
            height: 16rem;
            width: 16rem;
            bottom: 0;
            box-sizing: border-box;
            content: "";
            left: 0;
            margin: auto;
            position: absolute;
            right: 0;
            top: 0;
          }
          > span {
            display: none;
          }
        }
        body > .loading-mask {
          z-index: 9999;
        }
        ._block-content-loading {
          position: relative;
        }
        [data-role="main-css-loader"] {
          display: none;
        }
      }
    }
  }
}
