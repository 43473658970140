.footer{
  &-top {
    &-container{
      @include container;
      ul{
        @include flex;
        @include flexBetween;
        li{
          font-size: 18px;
          text-align: center;
          text-transform: uppercase;
          list-style: none;
          padding: 40px 0;
          @include flex;
          @include flexCenter;
          text-align: left;
          &:before{
            content: url($imageDir + "icons/shipping.svg");
            display: inline-block;
            vertical-align: middle;
            margin-right: 20px;
            line-height: normal;
          }
          &:first-child{
            &:before{
              content: url($imageDir + "icons/lock.svg");
            }
          }
          &:last-child{
            &:before{
              content: url($imageDir + "icons/ssl.svg");
            }
          }
        }
      }
    }/* .footer-top-container */
  }/* .footer-top */
  &-main{
    background: $colorSecondary;
    &-container{
      @include container;
      @include flexBetween;
      @include flexWrap;
      padding-top: 45px;
      padding-bottom: 45px;
      .section{
        color: $colorWhite;
        text-align: left;
        width: 33.33%;
        padding: 20px 0;
        &.footer-main-menu-locations{
          width: 100%;
        }
        >*{
          text-align: left;
          color: $colorWhite;
        }
        h3{
          width: 100%;
          margin-bottom: 40px;
        }
        ul{
          li{
            a{
              color: $colorWhite;
            }
          }
        }
      }
    }
    &-menu {
      &-service{
        @include flex;
        @include flexWrap;
        ul{
          width: auto;
          padding-right: 10px;
          li{
            a{
              font-size: 18px;
              word-break: break-all;
            }
          }
        }
        &:last-child{
          padding-right: 0;
        }
      }
      &-payment{
        ul{
          @include flex;
          li{
            width: 100%;
            margin-right: 20px;
          }
        }
      }
      &-locations {
        width: 100%;
        ul{
          @include flexBetween;
          li{
            margin-right: 30px;
            width: 100%;
            .location{
              height: 100%;
              a{
                display: inline-block;
                border: 1px solid $colorWhite;
                padding: 25px;
                text-align: center;
                font-size: 18px;
                height: 100%;
                &:before{
                  content: url($imageDir + "icons/pin.svg");
                  display: block;
                  text-align: center;
                  margin-bottom: 20px;
                  line-height: normal;
                }
              }
            }
            &:last-child{
              margin-right: 0;
            }
          }
        }
      }
    }
  } /* .footer-main */
  &-bottom{
    background: #707070;
    padding: 20px 0;
    &-navigation{
      .copyright{
        width: 100%;
        text-align: center;
        color: $colorWhite;
        font-size: 18px;
        display: block;
      }
      ul{
        @include flexCenter;
        margin-top: 15px;
        li{
          a{
            color: $colorWhite;
            padding: 0 10px;
            font-size: 18px;
            border-right: 2px solid $colorWhite;
          }
          &:last-child{
            a{
              border-right: 0;
            }
          }
        }
      }
    }
  }
}/* .footer */