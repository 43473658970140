*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  outline: none;
}

html {
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
  font-size: 1.8rem;
  line-height: 3.1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $colorFont;
  margin: 0 auto;
    padding-top: 18.9rem;
    &.cms-home{
        padding-top: 0;
        #maincontent{
            padding-top: 18.9rem;
        }
    }
  img {
    display: block;
      max-width: 100%;
  }
}
.minicart-wrapper{
    ._block-content-loading{
        top:0;
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;

    }
}
.price-final_price {
    @include flexWrap;
    align-items: flex-end;
    .price-label{
        display: none;
    }
    .percentage {
        color: $colorPrimary;
        @include fontSmaller;
        margin-right: 1.5rem;
    }
    .normal-price{
        span{
            line-height: normal;
          &.price-label{
              display: none;
            margin-right: 1rem;
          }
        }
    }
    .special-price {
        color: $colorPrimary;
        line-height: normal;
        span{
            line-height: normal;
        }
        .price-label {
            display: none;
        }
    }

    .old-price {
        padding-right: 1rem;
        @include flexCenter;
        text-decoration: line-through;
        width: auto;
        color: $colorSecondary;
        padding-left: 1rem;
        opacity: .5;
        font-weight: normal;
        span {
            @include fontSmaller;
            line-height: normal;
        }
        .price-label {
            display: none;
        }
    }
}
.fotorama{
    &-item{
        .fotorama{
            &__thumb-border{
                border-color: $colorPrimary;
            }
            &__active{
                .fotorama{
                    &__dot{
                        background-color: $colorPrimary;
                        border-color: $colorPrimary;
                    }
                }
            }
        }
    }
}
.paypal{
    input{
        width: auto;
        border:0;
    }
}

.password-strength-meter{
    font-size: 14px;
    #password-strength-meter-label{
        font-weight: 800;
    }
}
.fal {
  font-weight: 400;
}
p{
    margin-bottom: 1.5rem;
}
::selection {
  background-color: $colorPrimary;
  color: $colorWhite;
}
#block-shipping{
    display: none;
}

.block{
    &-title{
        @include robotoSlab;
        > *{
            color: $colorSecondary;
            margin-bottom: 0;
            width: 100%;
            text-transform: uppercase;
            padding: 25px 0;
            font-size: 36px;
        }
    }
}

.scrollTop{
    position: fixed;
    bottom: -200px;
    right: 15px;
    color: #fff;
    display: inherit;
    z-index: 10;
    transition: all ease .2s;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 800;
    &.scroll{
        bottom: 30px;
    }
    &:before{
        content: url($imageDir + "icons/arrow_scroll_top.svg");
        display: block;
        line-height: normal

    }
}
.text{
    .links{
        a{
            display: inline-block;
            width: auto;
            height: auto;
            padding: 15px 20px;
            background:$colorPrimary;
            color: $colorWhite;
            font-size: 14px;
            font-weight: 800;
            text-transform: uppercase;
            line-height: normal;
        }
    }
}
.section-item-title.nav-sections-item-title{
    display: none;
}
.mobile{
    display: none;
}


.block.widget{
  .block-title{
      > *{
          color: $colorPrimary;
      }
  }
}
input, select{
    width: 100%;
    &[type="checkbox"]{
        width: auto;
        display: inline-block;
    }
}
.columns{
    @include container;
    @include flex;
    flex-direction: row-reverse;
    padding-top: 8rem;
    padding-bottom: 10rem;
    justify-content: center;
    .column{
        width: 100%;
    }
}
.column{
    table {
        width: 100%;
        border-collapse: collapse;
        thead {
            tr {
                font-size: 1.8rem;
                line-height: 1;
                font-weight: bold;
                text-align: center;
                &:first-child {
                    text-align: left;
                }
            }

            th {
                padding: 0 1rem 1rem;
                text-align: center;
                font-size: 1.8rem;
                &.col{
                    border-bottom: 0.1rem solid rgba(0, 0, 0, 0.5);
                }
                &.subtotal {

                }
                &.item {
                    text-align: left;
                    padding-left: 0;
                }
                &.action {
                    padding: 0;
                    width: 24px;

                }
                &.price {

                }
            }
        }
        tbody{
            width: 100%;
            padding: .6rem 0;
            border-bottom: 1px solid $colorGray;
        }
        tr {
            &.item-actions{
                td{
                    padding-bottom: 0;
                    .actions-toolbar{
                        margin-top: 0;
                        position: absolute;
                        right: 0;
                        bottom: 100%;
                    }
                }
            }
            .control {
                &.size {
                    text-align: right;
                    display: flex;
                    justify-content: flex-end;
                    padding-right: 8.2rem;
                }
            }
            td {
                padding: 0;
                text-align: right;
                &.amount{
                    padding: 1rem 0;
                }
                &.col {
                    position: relative;
                    text-align: center;
                    vertical-align: center;
                    padding: 0 .6rem;
                    display: table-cell;
                    &.item {
                        display: block;
                        width: 100%;
                        padding: .6rem 0;
                        a {
                            display: table-cell;
                            span {

                                position: relative;
                                display: block;

                                img {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    height: 100%;
                                }
                            }
                        }
                        .product {
                            &-item {
                                &-name {
                                    @include font;
                                    font-size: 2.4rem;
                                    padding-top: 0;
                                }

                                &-details {
                                    display: table-cell;
                                    padding-left: 2.5rem;
                                    vertical-align: middle;

                                    text-align: left;
                                }

                                &-brand {
                                    width: 100%;
                                    display: block;
                                    font-size: 1.5rem;
                                    text-transform: uppercase;

                                }
                            }

                            strong {
                                line-height: 1;
                            }
                        }
                    }
                    &.price {

                        .cart{
                            &-price{
                                font-weight: normal;
                            }
                        }
                    }
                    &.qty {

                        .qty{
                            text-align: center;
                        }

                        label{
                            display: inline-block;
                        }
                        .label {
                            display: none;
                        }

                        input {

                            display: inline-block;
                            @include fontDefault;
                            font-size: 1.6rem;
                            font-weight: normal;
                            cursor: pointer;
                            padding: .5rem 1rem;

                            width: 6rem;
                            border: 1px solid rgba($colorGray, 0.2);

                            color: $colorBlack;
                        }
                    }
                    &.subtotal {

                    }
                    &.action{
                        padding: 0;

                        .action{
                            text-align: center;
                            display: inline-block;
                            &-delete{
                                &:before{
                                    display: inline-block;
                                }
                            }
                            span{
                                height: auto;
                                position: inherit;
                                overflow: initial;
                                padding-top: .3rem;
                                display: inline-block;
                                width: 100%;
                                font-size: 1.4rem;
                                font-weight: 300;
                                color: $colorPrimary;
                            }
                        }
                    }

                }
            }
        }

        .item {
            &-options {
                margin-top: 1rem;
                font-size: 1.6rem;
                line-height: 2rem;
                @include flexWrap;
                dt{
                    width: 7rem;
                    font-weight: bold;
                }
                dd{
                    width: calc(100% - 7rem);
                }
            }

            &-actions {
                position: relative;

                td {
                    padding: 0;
                    text-align: right;
                    padding-bottom: 2.5rem;

                    .actions-toolbar {
                        position: relative;
                        display: inline-block;
                    }
                }
            }
        }

        &.cart.item {
            border-bottom: 0.1rem solid rgba(0, 0, 0, 0.5);
        }
    }
}
.cms-page-view{
    .columns{
        .column{
            .page-info-main{
                padding: 2rem;
                ul{
                    padding-left: 20px;
                    margin-bottom: 1.5rem;
                    li{
                        list-style: disc ;
                    }
                }
                h2, h3{
                    text-align: left;
                    margin-top: 4rem;
                    &:first-child{
                        margin-bottom: 0;
                        margin-top: 0;
                    }
                }
            }
        }
    }

}
.breadcrumbs{
    background: top center;

    border-bottom: 3px solid $colorSecondary;
    ul{
        @include container;
        @include flex;
        li{
            padding: 7px 10px;
            font-size: 14px;
            position: relative;
            color: $colorSecondary;
            a{

            }


            strong{
                color: $colorSecondary;
                font-size: 14px;
            }
            &:last-child{
                border-right: 0;
            }
            a{
                color: $colorSecondary;
                font-size: 14px;
                display: inline-block;
                vertical-align:  middle;
                &:after{
                    content: "/";
                    font-size: 2rem;
                    margin-left: 1rem;
                    display: inline-block;
                    vertical-align:  middle;
                }
            }
        }
    }
}
fieldset{
    border: 0;
}
.filter {
    padding-right: 2rem;
    text-align: right;
    &-clear{

        width: 100%;
        margin-right: 0;
        font-size: 1.6rem;
    }

    &-actions{
        display: inline-block;
        width: 100%;
        text-align: center;
        padding: 0 0 2rem;
        border-bottom: 1px solid $colorBorder;
        margin-bottom: 2rem;
        a{
            display: inline-block;
        }
    }
    &-current{
        display: inline-block;
        width: 100%;
        text-align: öeft;
        padding: 1.5rem 0;

        ol{
            justify-content: flex-start;
            @include flex;
            .item + .item{
                padding-left: 1rem;
            }
            li{

                padding: 0 1rem;
                padding-left: 0;

                &:last-child{
                    border:0;
                    padding-right: 0;
                    .filter{
                        &-label{

                        }
                    }
                }
                .filter {

                    &-label {
                        width: 100%;
                        @include fontHeadline;
                        font-weight: 700;
                        font-size: 1.3rem;
                        padding-bottom: .5rem;
                        color: $colorSecondary;

                    }
                    &-value{
                        border-radius: .4rem;
                        display: inline-block;
                        padding: 0rem .8rem;
                        border: 1px solid $colorPrimary;
                        line-height: normal;
                        font-weight: 300;
                        font-size: 1.6rem;
                        line-height: 3.8rem;
                        color: $colorPrimary;
                    }
                }
                a, span{
                    display: inline-block;
                    &.remove{
                        padding: 0;
                        margin: 0;
                        color: $colorSecondary;
                        span{
                            display: none;
                        }
                        &:before{
                            content: "\f410";
                            @include fontIcon;
                        }
                    }
                }

            }
        }
    }
    &-title{
        text-align: left;
        cursor: pointer;
        display: inline-block;
        width: 100%;
        padding: 2rem 0;
        font-size: 1.2rem;
        text-transform: uppercase;
        display: none;
        &:after{
            vertical-align: middle;
            width: 3rem;
            display: inline-block;

            margin-left: 1.8rem;
            float: right;
        }
        i{
            display: none;
            &:before{
                display: none;
            }
        }
    }
    &-content{
        width: 100%;
        text-align: left;
        &.active{
            display: inline-block;
        }
        margin-bottom: 5rem;
    }
    &-options{
        @include flex;
        flex-wrap: wrap;
        &-title{
            width: 100%;
            padding: 1.5rem;
            text-align: center;
            padding-bottom: 0;
            @include font;
            font-weight: 400;
            font-size: 1.3rem;
            padding: 0;
            text-align: left;
            text-transform: uppercase;
            color: $colorPrimary;
            margin-top: 1rem;

        }
        &-content{
            width: 100%;
            padding: 1.5rem 0;
            text-align: center;
            border-bottom: 1px solid $colorBorder;
            ol{
                li{
                    border-bottom: 1px solid $colorBorder;
                    margin-bottom: .5rem;
                    padding-bottom: .5rem;
                    input{
                        width: auto;
                    }
                    label{
                        width: 100%;
                        padding-left: 1rem;
                    }
                    &:last-child{
                        margin-bottom: 0;
                        border-bottom: 0;
                    }
                }
                li, a{
                    text-align: left;
                    @include flex;
                    @include font;
                    width: 100%;
                    color: $colorSecondary;
                    .count{
                      color: $colorPrimary;
                     margin-left: auto;
                    }
                }
            }
            &:last-child{
                border: 0;
            }
            .swatch{
                &-attribute{
                    &-options{
                        @include flex;
                        justify-content: flex-start;
                        flex-wrap: wrap;
                        a{
                            border-radius: .4rem;
                            display: inline-block;
                            padding: 1rem .8rem;
                            border: 1px solid $colorPrimary;
                            line-height: normal;
                            font-weight: 300;
                            font-size: 1.6rem;
                            margin-right: 1rem;
                            margin-bottom: 1rem;
                            color: $colorPrimary;
                            padding: 5px 5px;
                            width: 3.6rem;
                            height: 3.6rem;
                            opacity: .6;
                            &:hover{
                                opacity: .8;
                            }
                            > div{
                                line-height: normal;
                            }
                        }
                    }
                    &.color{
                        .swatch-attribute-options{
                            a{
                                padding: 0;
                                width: 3rem;
                                height: 3rem;
                                position: relative;
                                border: 0;
                                border-radius: .4rem;
                                overflow: hidden;
                                border: 1px solid rgba($colorBlack, 0.1);
                                margin-bottom: 1rem;
                                div{
                                    position: absolute;
                                    width: 100%;
                                    height: 100%;
                                    left: 0;
                                    top: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &-count{
        &-label{
            display: none;
        }
    }
}

.minicart-wrapper{
    a{
        .counter.qty{
            .counter-label{
                display: none;
            }
            .counter-number{
                border-radius: 50px;
                background: $colorWhite;
                display: inline-block;
                color: $colorPrimary;
                width: 23px;
                height: 23px;
                font-size: 1.4rem;
                line-height: 20px;
                text-align: center;
                position: absolute;
                top: 0px;
                right: 20px;
                -webkit-border-radius: 50px;
                -moz-border-radius: 50px;
                border-radius: 50px;
                border: 2px solid $colorPrimary;
                font-weight: 800;
                @include defaultFastTrans;
            }
            &.empty{
                display: none;
            }
        }
    }
}
.cart-empty{
    text-align: center;

}
.block-minicart{
    position: absolute;
    background: #fff;
    z-index: 1;
    right: 0;
    min-width: 150px;
    .block-content{
        padding: 15px;
        border: 1px solid $colorSecondary;
        .items-total{
            color: $colorPrimary;
            margin-bottom: 50px;
        }
        > .actions{
            @include flexBetween;
            .actions{
                width: 49%;
            }
            .primary{
                background: $colorPrimary;
                a{
                    display: inline-block;
                    padding-top: 0.4rem;
                    padding-bottom: 0.5rem;
                    padding-left: 2rem;
                    padding-right: 2rem;
                    color: $colorWhite;
                    width: 100%;
                    font-size: 14px;
                    span{
                        display: inline-block;
                    }
                    &:before{
                        display: inline-block;
                        margin-right: 10px;
                    }
                }

            }
            .secondary{
                width: 49%;
                @include flex;
                a{
                    margin-right: 0 !important;
                    font-size: 14px;
                    span{
                        display: inline-block;
                    }
                    &:before{
                        display: inline-block;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}
.cms-no-route, .categorypath-verleih,  .cms-page-view, .categorypath-rental {
    #maincontent {
        .columns, .category-cms {
            .column {
                width: 90%;
                max-width: 820px;
                margin: 0 auto;
                h1,h2,h3, h4, h5{
                    text-align: left;
                }
                img{
                    margin-bottom: 20px;
                }
                iframe{
                    max-width: 100%;
                }
                a{
                    color: $colorPrimary;
                }
            }
        }
    }
}

