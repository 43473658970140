.page-main, .footer-main, .header-main, .cookie, #notice-cookie-block, .modals-wrapper{
  .secondary {
    @include flex;
    align-items: center;
  }
  .action {
    @include fontDefault;
    cursor: pointer;

    &.primary, &.tocart.primary, &.allow  {
      padding-top: 0.4rem;
      padding-bottom: 0.5rem;
      padding-left: 2rem;
      padding-right: 2rem;
        display: inline-block;

      color: $colorWhite;
      background-color: $colorPrimary;
      @include defaultFastTrans;
      @include font20;

      border: 1px solid $colorPrimary;
      &:hover {
        @include defaultFastTrans;

      }
      &.tocart.disabled {
        border-color: $colorSuccess;
        background-color: $colorSuccess;
      }
      @media only screen and (max-width: 1024px) {
        font-size: 1.7rem !important;
        line-height: 2.4rem !important;
        padding-top: 0.4rem !important;
        padding-bottom: 0.6rem !important;
        padding-right: 1.7rem !important;
        padding-left: 1.7rem !important;margin-bottom: 8px;
      }
    }
    &.secondary, &.action-edit-address ,&.action-select-shipping-item, &.back, &.remind, &.tocart, &.reload, &.clear, &.update, &.continue, &.action-hide-popup, &.action-show-popup, &.action-update, &.action-cancel, &.share {
      @include defaultFastTrans;
      @include flex;
      align-items: center;
      padding-top: 0.4rem;
      padding-bottom: 0.5rem;
      padding-left: 2rem;
      padding-right: 2rem;
      color: $colorBlack;
      background-color: transparent;
      border: 0.1rem solid black;

      display: inline-block;
      text-align: center;
      align-items: flex-start;
      @include defaultFastTrans;

      @media only screen and (max-width: 1024px) {
        font-size: 1.7rem !important;
        line-height: 2.4rem !important;
        padding-top: 0.4rem !important;
        padding-bottom: 0.6rem !important;
        padding-right: 1.7rem !important;
        padding-left: 1.7rem !important;
      }
    }
    &.tocategory.secondary, &.totag.secondary {
      @include defaultFastTrans;
      padding-right: 1.2rem;
      padding-left: 1.2rem;
      padding-top: 0.1rem;
      padding-bottom: 0.2rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      display: inline-block;

      border: 0.1rem solid $colorWhite;
      margin: 0.1rem;
      color: white;
      &:hover {
        @include defaultFastTrans;

      }
    }
    .btnSecondary {
      @include defaultFastTrans;
      padding-right: 1.2rem;
      padding-left: 1.2rem;
      padding-top: 0.1rem;
      padding-bottom: 0.2rem;
      border: 0.1rem solid $colorSecondary;
      @include fontSmaller;
      margin: 0.1rem;
      &:hover {
        border: 0;
        @include defaultFastTrans;

      }
    }
  }
}

#minicart-content-wrapper .secondary .action, .block-dashboard-orders .order a.view {
  @include defaultFastTrans;
  padding-top: 0.4rem;
  padding-bottom: 0.5rem;
  padding-left: 2rem;
  padding-right: 2rem;;
  margin-right: 2rem;
  color: $colorBlack;
  background-color: transparent;
  border: 0.1rem solid black;
  @include defaultFastTrans;
  display: inline-block;
  width: 100%;
  &:hover {
    border: 0.2rem solid black;
  }
}
.block-dashboard-orders .order a.view {
  width: auto;
  font-size: 1.6rem;
  text-transform: none;
  margin-left: 3rem;
  display: inline-block;
  &:before{
    display: none;
  }
  span{
    width: auto;
    overflow: auto;
    height: auto;
    position: relative;
  }
}
.emptyButton {
  padding: 0;
  border: none;
  margin: 0;
  background-color: transparent;
  margin-left: 0.1rem;
  &:hover {
    margin-left: 0rem;
    border: none;
  }
}



.top-container .overlay .action{
  display: inline-block;
  margin-top: 5rem;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 150%;
  letter-spacing: 0.1rem;
  color: $colorWhite;
  padding: 1.4rem 2rem;
  border: 0.2rem solid $colorWhite;
  background-color: transparent;
  margin: 0.2rem;
  &:hover {
    margin: 0;
    background-color: $colorWhite;
  }
}
.page-main  .actions-toolbar {
  display: flex;
  margin-top: 30px;
  > .primary {
    margin-right: 1.5rem;
  }
}
.action.back, .action.save{

}
.password-info{
  font-size: 14px;
  color: $colorPrimary;
}
.page {
  &-main, &-header {

    .action {
      &:last-child{
        margin-right: 5px !important;
      }
      &.edit, &-edit, &.change-password, &-change-password, &.view, &.delete, &-delete, &.print {
        text-decoration: none;
        max-width: inherit;
        min-height: inherit;
        margin: 0;
        border: none;
        color: white;
        width: auto;

        text-align: right;
        padding: 5px;

        display: inline-block;
        font-size: 0;

        > span {
          height: 0.1rem;
          overflow: hidden;
          position: absolute;
          width: 0.1rem;
        }

        &:before {
          @include icon;
          width: auto;
          height: auto;
          @include fontIcon;

          font-size: 2rem;
          color: $colorSecondary;
          font-weight: 300;
        }
      }
      &.print {
        &:before {
          content: '\f02f';
        }
      }
      &.view {
        &:before {
          content: '\f06e';
        }
      }

      &.edit, &-edit {
        &:before {
          content: '\f044';
        }
      }
      &.delete, &-delete{
        &:before{
          content: '\f2ed';
        }
      }
      &.change-password, &-change-password {
        margin-right: 0;

        &:before {
          content: '\f09c';
        }
      }
    }
  }
}


.widget.magento-widget.category-overview .widget-content {
  margin: 0 auto;
  display: flex;
  padding-bottom: 12rem;
  padding-top: 10rem;
  justify-content: center;
  a {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }
}
input.action.submit.secondary:hover {
  margin: 0 0.1rem;
}

.product-info-category {
  ul li a.action.secondary {
    &.tocategory {
      font-size: 1.4rem !important;
      line-height: 2rem !important;
      @media only screen and (max-width: 1024px) {
        font-size: 1.2rem !important;
        line-height: 1.4rem !important;
      }
    }
  }

}
ul.categories.list {
  li a.action.secondary {
    &.tocategory {
      font-size: 1.4rem !important;
      line-height: 2rem !important;
      @media only screen and (max-width: 1024px) {
        font-size: 1.2rem !important;
        line-height: 1.4rem !important;
      }

    }
  }
}
