@font-face {
  font-family: 'Roboto Slab';
  src: url('../../fonts/RobotoSlab-Regular.eot');
  src: url('../../fonts/RobotoSlab-Regular.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/RobotoSlab-Regular.woff2') format('woff2'),
  url('../../fonts/RobotoSlab-Regular.woff') format('woff'),
  url('../../fonts/RobotoSlab-Regular.svg#RobotoSlab-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
font-family: 'Roboto Slab';
  src: url('../../fonts/RobotoSlab-Light.eot');
  src: url('../../fonts/RobotoSlab-Light.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/RobotoSlab-Light.woff2') format('woff2'),
  url('../../fonts/RobotoSlab-Light.woff') format('woff'),
  url('../../fonts/RobotoSlab-Light.svg#RobotoSlab-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
font-family: 'Roboto Slab';
  src: url('../../fonts/RobotoSlab-Bold.eot');
  src: url('../../fonts/RobotoSlab-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/RobotoSlab-Bold.woff2') format('woff2'),
  url('../../fonts/RobotoSlab-Bold.woff') format('woff'),
  url('../../fonts/RobotoSlab-Bold.svg#RobotoSlab-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
font-family: 'Roboto Slab';
  src: url('../../fonts/RobotoSlab-Medium.eot');
  src: url('../../fonts/RobotoSlab-Medium.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/RobotoSlab-Medium.woff2') format('woff2'),
  url('../../fonts/RobotoSlab-Medium.woff') format('woff'),
  url('../../fonts/RobotoSlab-Medium.svg#RobotoSlab-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../fonts/montserrat-bold.eot');
  src: url('../../fonts/montserrat-bold.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/montserrat-bold.woff2') format('woff2'),
  url('../../fonts/montserrat-bold.woff') format('woff'),
  url('../../fonts/montserrat-bold.ttf') format('truetype'),
  url('../../fonts/montserrat-bold.svg#montserratbold') format('svg');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../../fonts/montserrat-bolditalic.eot');
  src: url('../../fonts/montserrat-bolditalic.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/montserrat-bolditalic.woff2') format('woff2'),
  url('../../fonts/montserrat-bolditalic.woff') format('woff'),
  url('../../fonts/montserrat-bolditalic.ttf') format('truetype'),
  url('../../fonts/montserrat-bolditalic.svg#montserratbold') format('svg');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../../fonts/montserrat-light.eot');
  src: url('../../fonts/montserrat-light.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/montserrat-light.woff2') format('woff2'),
  url('../../fonts/montserrat-light.woff') format('woff'),
  url('../../fonts/montserrat-light.ttf') format('truetype'),
  url('../../fonts/montserrat-light.svg#montserratbold') format('svg');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../../fonts/montserrat-medium.eot');
  src: url('../../fonts/montserrat-medium.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/montserrat-medium.woff2') format('woff2'),
  url('../../fonts/montserrat-medium.woff') format('woff'),
  url('../../fonts/montserrat-medium.ttf') format('truetype'),
  url('../../fonts/montserrat-medium.svg#montserratbold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin font{
  font-family: 'Montserrat', sans-serif;
}
@mixin fontIcon {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
}
@mixin fontDefault {
  @include font;
    font-size: 2rem;
    color: $colorBlack;
    line-height: 3.1rem;
  font-weight: normal;
    @media only screen and (max-width: 768px) {
    font-size: 1.7rem;
    line-height: 2.4rem
    }
}
@mixin robotoSlab {
font-family: 'Roboto Slab';
font-weight: bold;
}
@mixin montserrat {
  font-family: 'Montserrat', sans-serif;
}


@mixin fontHeadline {
  @include robotoSlab;
  color: $colorFont;
  margin: 0;
  padding: 0 0 0 0;
}
@mixin font20 {
  font-size: 2rem;
  line-height: 3.1rem;
  @media only screen and (max-width: 768px) {
    font-size: 1.7rem;
    line-height: 2.4rem
  }
}
@mixin fontSmallest {
  font-size: 1rem;
  line-height: 1.4rem;
}
@mixin fontSmaller {
  font-size: 1.4rem;
}
@mixin fontSmall {
  font-size: 1.6rem;
  line-height: 2.6rem;
}
@mixin fontLarge {
  line-height: 3.7rem;
  font-size: 2.4rem;
}
@mixin fontLarger {
  font-size: 3.2rem;
  line-height: 4.5rem;
}
@mixin fontLargest {
  font-size: 4.2rem;
  line-height: 5.2rem;
  letter-spacing: 0.04rem;
  text-transform: uppercase;
}
@mixin fontxLargest {
  font-size: 5.2rem;
  line-height: 7.2rem;
  font-weight: bold;
}

/*=============================================
=            Price Fonts            =
=============================================*/

@mixin fontPriceProducts {
  font-weight: bold;
}

@mixin fontPriceProductsSpecial {
  font-weight: bold;
  color: $colorSecondary;
}

@mixin fontPriceProductsSale {
  font-weight: bold;
  text-decoration: line-through;
}

@mixin fontPriceProductSale {
  font-weight: bold;
  text-decoration: line-through;
}

body {
  @include fontDefault;
}
h1, h2, h3, h4, h5, h6 {
  @include fontHeadline;
  text-align: center;
}
h1 {
  font-size: 5.2rem;
  font-weight: bold;
  line-height: 7.2rem;
  padding-bottom: 0.6rem;
  &:not(:first-child) {
    padding-top: 0rem;
  }
  &.noPadding {
    padding-bottom: 0;
  }
  @media only screen and (max-width: 768px) {
    font-size: 3.4rem;
    line-height: 4.6rem;
  }
}
h2 {
  font-size: 3.2rem;
  font-weight: 600;
  line-height: 4.5rem;
  margin-bottom: 4rem;
  &:not(:first-child) {
    margin-bottom: 0rem;
  }
  @media only screen and (max-width: 768px) {
    margin-bottom: 5rem;
    font-size: 2.4rem;
    line-height: 3.4rem;
  }
}
h3 {
    font-size: 2.4rem;
    line-height: 3.1rem;
    letter-spacing: 0;
    font-weight: bold;
  &:not(:first-child) {
    padding-top: 0rem;
  }
  @media only screen and (max-width: 768px) {
    font-size: 1.7rem;
    line-height: 2.4rem;
  }
}
h4 {
  font-size: 1.6rem;
  line-height: 2.6rem;
  font-weight: bold;
}

a {
  color: $colorFont;
  text-decoration: none;
}
b, strong {
  font-weight: 700;
}

ol, ul {
  margin: 0;
  padding: 0 0 0 0;
  list-style: none;
}
